.leaflet-container {
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
}

.leaflet-touch {
  .leaflet-bar {
    a {
      width: 40px;
      height: 40px;
      line-height: 40px;
      span {
        font-size: 20px;
      }
    }
  }
  .leaflet-control-layers-toggle {
    width: 60px;
    height: 60px;
  }
}

.leaflet-center {
  left: 50%;
  transform: translate(-50%, 0%);
}

.leaflet-control-layers .leaflet-control-layers-list {
  display: block;
  position: relative;
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded {
  padding: 0px;
}